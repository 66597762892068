const config = {
  apiUrl:
    'https://3q4t3yw5hnfb5kpcrnped5n2dy.appsync-api.us-east-1.amazonaws.com/graphql',
  apiKey: 'da2-x4cidbuubvbcblqf3q25uiclp4',
  userPoolId: 'us-east-1_e5s0xfUtd',
  userPoolWebClientId: '75gtancjimt10jitknjei42ffd',
  url: 'https://partnerapp-staging.tripshock.com',
  siteUrl: 'https://staging-front-end.tripshock.com',
  CHAT_WIDGET_KEY: 'g7jL8uRUV/vtTtyctdU7l3WtnievLVsODlA/p2Rrq7A=',
  CHAT_WIDGET_ID: '42b42b38-250e-4927-bf6a-3c8b87f59a99',
  CHAT_WIDGET_SRC:
    'https://dg9yx063wiiht.cloudfront.net/amazon-connect-chat-interface-client.js',
  CHAT_WIDGET_SNIPPET_ID:
    'QVFJREFIaUpTVGJkNWhNc0Q1WHpHYnFQTkJyYXN0TENLbGdHZzNOenF2d2RJblBkYlFGVWI4M21JWk9TQ2g5UUI1UEZiNUFaQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNZ1l4QVNIdGVHTGxoeEoxQUFnRVFnQ3Y1aDBvK1h2YVVwaVVUd0JNRXZQbTJibFM5MUx1WitVUllaakt2eElXNjFKY0h1STI3WVNQS2IwazI6OkhidnkzWERGYTR0Vk5jS2ZLQkJmaUF3cWswMHRzczQwSDVtZXN3ejZDUFhyNWVyS1dNdWV5dTlUa3JNcE00RVFNZTZEajRRcXBlQVExNWdCbXJjQVl1Q1A2dVNRL294TXllbFFWdnNUR2pXOXdNN1ZrcTY4ZDQvUklvajQ1YVpOZnp0VThmUkJlU0RFOUR6NUxpeWtMSzUzWHUxbm45bz0=',
};

//@ts-ignore
const env = process.env.APP_MANIFEST?.extra?.ENV;
export const isProd = env === 'prod';

if (env === 'staging') {
  config.apiUrl =
    'https://76t7syjv7nagzcofubygf7v36y.appsync-api.us-east-1.amazonaws.com/graphql';
  config.apiKey = 'da2-rgx45csdvnegbd5ekkpr4prr6y';
}

if (env === 'prod') {
  config.apiUrl =
    'https://x4umnvav4ff4rb6ewwcsg5nwjy.appsync-api.us-east-1.amazonaws.com/graphql';
  config.apiKey = 'da2-oixkowdn4rdhjblg4geespducu';

  config.userPoolId = 'us-east-1_GMitD0SFe';
  config.userPoolWebClientId = '63580skdd9tngg2eoes618momu';
  config.url = 'https://partnerapp.tripshock.com';
  config.siteUrl = 'https://tripshock.com';
  config.CHAT_WIDGET_KEY = 'Dj0SCnBxf/yH8ltvmr+ynq2m0TzgDP0gjj6anBTeiqA=';
  config.CHAT_WIDGET_ID = 'bde6a1d7-76dc-4084-8a88-30d022a61662';
  config.CHAT_WIDGET_SRC =
    'https://dend6g4sigg57.cloudfront.net/amazon-connect-chat-interface-client.js';
  config.CHAT_WIDGET_SNIPPET_ID =
    'QVFJREFIanUzYnhvNFBocFpzVHdoQjZmUUtOd1FaMjMxR3ZDRG9RRWNFUkFkM3dWVWdIb25yQWxqMno1ZEJObXpjMzNsSnpLQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNWjhtcmoxRThiRmFScHhTd0FnRVFnQ3NhU295UkZpRWpPMnF2OVdJem4xazBNTkxMYTYyRm95eVdYK0NIK3hxYTkxNFBpdTFPR0RmL2NyRmc6OjJBZEhyY0xISmhYQ1RTS0JBS0MzeWdNVm40dEMzRU5kTTk0amhyVjRQMDlycVNDaWpQeFVNRG14Rm9rUW1PZnEzNnhsZjZzLzVrNWhRRU1nbDNIYy9qNHN1NmhiVGFOMXVXalMzM1dEd05qSnkvaEsyR3RPanM0c1pud1lSY2t6cnh5V0puaGg1Zk5kNExsdEVKUEQxVHZhRHdYS1BYST0=';
}

// const config = {
//   apiUrl: process.env.API_URL ?? devConfig.apiUrl,
//   apiKey: process.env.API_KEY ?? devConfig.apiKey,
//   userPoolId: process.env.USER_POOL_ID ?? devConfig.userPoolId,
//   userPoolWebClientId:
//     process.env.USER_POOL_WEB_CLIENT_ID ?? devConfig.userPoolWebClientId,
//   url: process.env.URL ?? devConfig.url,
//   siteUrl: process.env.SITE_URL ?? devConfig.siteUrl,
//   CHAT_WIDGET_ID: process.env.CHAT_WIDGET_ID || devConfig.CHAT_WIDGET_ID,
//   CHAT_WIDGET_KEY: process.env.CHAT_WIDGET_KEY || devConfig.CHAT_WIDGET_KEY,
//   CHAT_WIDGET_SNIPPET_ID:
//     process.env.CHAT_WIDGET_SNIPPET_ID || devConfig.CHAT_WIDGET_SNIPPET_ID,
//   CHAT_WIDGET_SRC: process.env.CHAT_WIDGET_SRC || devConfig.CHAT_WIDGET_SRC,
// };

export default config;
