import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';

import { changeLoading } from 'src/web/features/table/sliceTables';
import DeleteIcon from 'src/assets/icons/web/delete.svg';
import UnreadActionIcon from 'src/assets/icons/web/notifications/unreadAction.svg';
import ShowNotificationModal from './ShowNotificationModal';
import { useMutation } from '@apollo/client';
import {
  UPDATE_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
} from 'src/api/notifications';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';
import { getNotificationPath } from 'src/helpers/getNotificationsPathsMap';
import { useHistory } from 'react-router-dom';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';
import { NotFoundData } from '../UI/NotFoundData';
import { useStyles } from './styles';
import ConfirmModal from './ConfirmModal';

interface IProps {
  notifications: INotification[];
  setNotifications: React.Dispatch<React.SetStateAction<[] | INotification[]>>;
  refetch: any;
  setIsSpiner: React.Dispatch<React.SetStateAction<boolean>>;
  showReaded?: boolean;
}

export type ConfirmType = 'delete' | 'unread' | 'readall';

export const NotificationsList = ({
  notifications,
  setNotifications,
  refetch,
  setIsSpiner,
  showReaded,
}: IProps) => {
  const classes = useStyles();
  const loading = useSelector(changeLoading);
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const history = useHistory();
  const {
    setIsExistNewNotifications,
    setLastNotifications,
    filterLastNotificationsByRead,
  } = useNotificationsContext();

  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<ConfirmType>();
  const [confirmAction, setConfirmAction] = useState<Function>();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [choosedNotification, setChoosedNotification] =
    useState<INotification | null>(null);
  const mdMatches = useMediaQuery('(max-width: 1020px)');

  const [deleteNotification] = useMutation(DELETE_NOTIFICATIONS, {
    onCompleted: () => {
      refetch();
      setIsSpiner(false);
    },
  });
  const [updateNotification] = useMutation(UPDATE_NOTIFICATIONS, {
    onError: (error) => {
      dispatch(
        setNotification({
          type: 'error',
          message: error.message,
        })
      );
    },
  });

  const row_click_handler = (notification: any) => {
    if (!notification.isReaded) {
      handleRead(notification.id);
    }

    const path = getNotificationPath(notification);

    console.log(path);

    if (path) {
      history.push(path);
    }
  };

  const updateNotificationsState = (id: string, isReaded: boolean) => {
    const notificationsClone = JSON.parse(JSON.stringify(notifications));
    const needleIndex = notificationsClone.findIndex(
      (item: any) => item.id === id
    );
    notificationsClone[needleIndex].isReaded = isReaded;

    setNotifications(
      filterLastNotificationsByRead(notificationsClone, showReaded)
    );
    setIsExistNewNotifications(
      !!notificationsClone.filter((i: any) => !i.isReaded).length
    );
    setLastNotifications((prev) => prev.filter((item) => item.id !== id));
  };

  const handleDelete = (
    id: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsSpiner(true);
    updateNotificationsState(id, true);
    deleteNotification({
      variables: {
        prm: {
          id,
          username: user['cognito:username'],
        },
      },
    });
  };

  const handleRead = (id: any) => {
    updateNotificationsState(id, true);
    updateNotification({
      variables: {
        prm: {
          id,
          username: user['cognito:username'],
          isReaded: true,
        },
      },
    });
  };

  const handleUnread = (id: any) => {
    updateNotificationsState(id, false);
    updateNotification({
      variables: {
        prm: {
          id,
          username: user['cognito:username'],
          isReaded: false,
        },
      },
    });
  };

  const handleConfirm = (
    confirmType: ConfirmType,
    action: Function,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenConfirmModal(true);
    setConfirmAction(() => action);
    setConfirmType(confirmType);
  };

  return (
    <>
      <ConfirmModal
        open={openConfirmModal}
        confirmType={confirmType}
        setOpen={setOpenConfirmModal}
        action={confirmAction}
      />
      <ShowNotificationModal
        open={openPopup}
        setOpen={setOpenPopup}
        item={choosedNotification as INotification}
        setItem={setChoosedNotification}
      />
      {!loading && !notifications.length ? (
        <TableRow className={classes.table_row}>
          <TableCell className={classes.tableLoaderWrapper} width={100}>
            <Box className={classes.tableLoader}>
              <Typography variant="button" align="center">
                <NotFoundData title={'No data'} />
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        notifications?.map((notification) => {
          return (
            <React.Fragment key={notification.id}>
              <TableRow
                onClick={() => row_click_handler(notification)}
                hover={true}
                className={classes.table_row}
                style={
                  notification.isReaded
                    ? { background: 'white' }
                    : { background: '#F9FCFD' }
                }>
                {!mdMatches && (
                  <TableCell className={classes.table_cell}>
                    <Box className={classes.nameCell}>
                      <Box
                        className={classes.newNotificationListDot}
                        style={{
                          opacity: notification.isReaded ? 0 : 1,
                        }}
                      />
                      {notification.title || 'N/A'}
                    </Box>
                  </TableCell>
                )}

                <TableCell className={classes.table_cell}>
                  <Box className={classes.dateCell}>
                    {moment(+notification.createdAt * 1000).format(
                      'MM/DD/YYYY'
                    )}
                  </Box>
                </TableCell>
                <TableCell className={classes.table_cell}>
                  <Box className={classes.timeCell}>
                    {moment(+notification.createdAt * 1000).format('hh:mm:ss')}
                  </Box>
                </TableCell>
                <TableCell className={classes.table_cell} align="left">
                  <Box className={classes.idCell}>
                    <Box className={classes.id_badge}>
                      # {notification.object_id || 'N/A'}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell className={classes.table_cell} align="left">
                  <Box width="120px">
                    {notification.isReaded && (
                      <Tooltip title="Mark as unread" interactive>
                        <IconButton
                          onClick={(e) =>
                            handleConfirm(
                              'unread',
                              () => handleUnread(notification.id),
                              e
                            )
                          }>
                          <UnreadActionIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip title="Delete">
                      <IconButton
                        onClick={(e) =>
                          handleConfirm(
                            'delete',
                            () => handleDelete(notification.id, e),
                            e
                          )
                        }
                        // className={classes.actionButton}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })
      )}
    </>
  );
};
