import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "src/assets/icons/light/close.svg";
import { useStyles } from "./styles";
import { useMutation } from "@apollo/client";
import { UPDATE_PARTNER } from "src/api/partners";
import { setNotification } from "src/redux/Notifications/sliceNotifications";
import { useDispatch } from "react-redux";
import { IPartner } from "src/web/pages/Settings";

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  data: IPartner;
  setData: React.Dispatch<React.SetStateAction<IPartner | null>>;
};

export const EditCompanyModal = ({ open, setOpen, data, setData }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [updatePartner, { loading }] = useMutation(UPDATE_PARTNER, {
    onCompleted: () => {
      dispatch(
        setNotification({
          message: "Info was updated successfully!",
          type: "success",
        })
      );
      setOpen(false);
    },
    onError: (error) => {
      dispatch(
        setNotification({
          message: error.message,
          type: "error",
        })
      );
    },
  });

  const onSubmitHandler = () => {
    updatePartner({
      variables: {
        prm: {
          user_id: data.id,
          id: data.id,
          data: {
            name: data.name,
            about_us: data.about_us,
          },
        },
      },
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.editModalRoot}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>About us</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div className={classes.textareaBox}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            value={data.about_us}
            onChange={(e) =>
              setData((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  about_us: e.target.value,
                };
              })
            }
            className={classes.textarea}
            minRows={6}
            maxRows={8}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            disabled={loading}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onSubmitHandler()}
            className={classes.saveButton}
            variant="contained"
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
