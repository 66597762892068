import React from 'react';

import { NotificationsContent } from 'src/web/components/Settings/Notifications';
import { IPartner } from '..';

const NotificationsPage = ({
  data,
  setData,
}: {
  data: IPartner;
  setData: React.Dispatch<React.SetStateAction<IPartner | null>>;
}) => {
  return <NotificationsContent data={data} setData={setData} />;
};

export default NotificationsPage;
