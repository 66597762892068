import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "src/web/themes/theme";

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "100%",
    },
    "& .MuiPaper-elevation24": {
      padding: "14px 13px 27px 50px",
      maxWidth: "753px",
      boxShadow: "0px 10.89px 43.36px rgba(17, 15, 15, 0.53)",
      borderRadius: "15px",
      overflow: "unset",
      "& .MuiTypography-root": {
        width: "100%",
      },
      "& .MuiDialogContent-root": {
        paddingLeft: 0,
      },
    },
    "@media (max-width: 750px)": {
      "& .MuiPaper-elevation24": {
        padding: "10px 10px 27px 25px",
      },
    },
    "@media (max-width: 600px)": {
      "& .MuiDialog-paper": {
        margin: "17px",
        width: "100%",
      },
    },
  },
  fieldsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 750px)": {
      "& .MuiPaper-elevation24": {
        flexWrap: "nowrap",
        flexDirection: "column",
      },
    },
  },
  fieldBox: {
    width: "50%",
    "@media (max-width: 750px)": {
      width: "100%",
    },
  },
  wrapper: {
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "10%",
    },
  },
  dialogTitle: {
    display: "flex",
    padding: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  closeIcon: {
    "&:hover": {
      background: "none",
      border: "1px solid #FAFAFA",
      padding: "11px",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: "26px",
    paddingTop: "27px",
    "@media (max-width: 750px)": {
      marginRight: "0",
    },
  },
  filterArea: {
    "& .MuiInputLabel-outlined": {
      color: "#333",
      paddingLeft: "22px",
      fontWeight: 500,
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "4px 24px !important",
    },
    "& .MuiInputBase-root": {
      height: "50px",
      borderRadius: "50px",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },
    "& .MuiInputBase-input": {
      padding: "15px 30px",
      color: "#333333",
      fontSize: "14px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px #333333 solid",
    },
    "& .MuiInputBase-adornedStart": {
      "& .MuiInputBase-input": {
        paddingLeft: 0,
      },

      "& span": {
        paddingLeft: "15px",
        color: "#333333",
      },
    },
  },

  button: {
    background: "linear-gradient(90deg, #03D9B1 0%, #1CBBD9 100%)",
    color: "white",
    textTransform: "none",
    borderRadius: "50px",
    boxShadow:
      "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    fontSize: "16px",
    padding: "6.5px 31px",
    fontWeight: 500,
    "&:hover": {
      background: "linear-gradient(90deg, #06C9A4 0%, #00A2C1 100%)",
      color: "#FFFFFF",
      boxShadow:
        "0px 13px 28px rgba(29, 203, 192, 0.2), 0px 8px 16px rgba(29, 203, 192, 0.06)",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  resetButton: {
    borderRadius: "50px",
    textTransform: "none",
    fontSize: "16px",
    padding: "6.5px 31px",
    fontWeight: 500,
    marginRight: "20px",
    "@media (max-width: 600px)": {
      marginBottom: "20px",
      width: "100%",
    },
  },
  autocompleteItem: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    margin: "10px",
  },
  popper: {
    zIndex: 2000,
    "& .MuiAutocomplete-option": {
      borderRadius: "15px",
    },
    "& .Mui-focused": {
      background: "#FAFAFA",
      borderRadius: "15px",
    },
    "& .Mui-selected": {
      background: "#FAFAFA",
      color: "#0DD1C5",
      borderRadius: "15px",
    },
    "& .MuiAutocomplete-listbox": {
      maxHeight: "250px",
      "& :hover": {
        background: "#FAFAFA",
        color: "#0DD1C5",
        borderRadius: "15px",
      },
    },
  },
  moreFiltersButton: {
    color: "#C4C4C4",
    fontSize: "16px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "flex-end",
    width: "168px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  moreFiltersButtonMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  dateFilter: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headerTitle: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",

      "& svg": {
        fontSize: "1.8rem",
        marginBottom: "4px",
        marginRight: "8px",
      },
    },
  },
  selectorLabel: {
    color: "#333 !important",
    paddingLeft: "6px",
    paddingRight: "6px",
    background: "white",
    marginLeft: "12px",
  },
  statusSelect: {
    borderRadius: "50px",
    width: "100%",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    color: "#6C6B6B",

    "& .MuiOutlinedInput-multiline": {
      padding: "0",
    },

    "& .MuiInputBase-root": {
      height: "100px",
      borderRadius: "25px !important",
      "& .MuiOutlinedInput-notchedOutline": {
        paddingLeft: "25px",
      },
    },

    "& .MuiInputBase-input": {
      padding: "15px 30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },

    "& .MuiSelect-select:focus": {
      background: "white",
    },
    "& fieldset": {
      border: "1px solid #C4C4C4 !important",
      paddingLeft: "8px",
    },

    "&:hover": {
      color: "#0DD1C5",

      "& fieldset": {
        border: "1px solid #0DD1C5 !important",
      },
    },

    "& .Mui-focused": {
      color: "#6C6B6B !importnant",
    },

    "& .MuiSelect-root": {
      height: "50px",
      paddingTop: 0,
      paddingBottom: 0,
      display: "flex",
      alignItems: "center",
      padding: "0 30px",
      "@media (max-width: 850px)": {
        height: "45px",
      },
    },
  },
  statusSelector: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  statusSelectPaper: {
    boxShadow: "0px 3.89px 23.36px #110F0F26",
    backgroundColor: "#FFFFFF",
    borderRadius: "25px",
    padding: "0 10px",

    "& .MuiList-padding": {
      padding: "15px 30px",
      "@media (max-width: 850px)": {
        padding: "16px 20px",
      },
    },

    "& .MuiMenuItem-root": {
      color: "#6C6B6B",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      fontWeight: 400,
      borderRadius: "10px",
      padding: "22px 28px",
      "@media (max-width: 850px)": {
        padding: "17px 24px",
      },
    },

    "& .MuiListItem-button:hover": {
      color: "#0DD1C5",
      backgroundColor: "#ecfbfa",
      borderRadius: "14px",
    },

    "& .Mui-selected": {
      color: "#C4C4C4",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      backgroundColor: "transparent",

      "&:hover": {
        color: "#C4C4C4",
        backgroundColor: "transparent",
      },
    },
  },
  dialogContent: {
    borderTop: "1px solid #FAFAFA",
    overflow: "inherit",
    "@media (max-width: 750px)": {
      overflow: "auto",
    },
  },
}));
