import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_PARTNER_ORDERS } from 'src/api/partners';
import ArrivalsIcon from 'src/assets/icons/light/components/arrivals.svg';
import { PageTable } from 'src/web/features/table';
import {
  page,
  setInitialPrm,
  prm as initialPrm,
  setLoading,
  setOffset,
  setDetailedFilters,
  changeInitialSorting,
} from 'src/web/features/table/sliceTables';
import { DetailedFilters } from 'src/web/components/Arrivals/Filter';
import { filters, sorting, detailedFilters } from './data';
import { ArrivalsList } from 'src/web/components/Arrivals/ArrivalsList';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { normalizePrm } from './data/normalizedPrm';
import moment from 'moment';
import Header from 'src/web/components/Arrivals/Filter/Header';
import { useMediaQuery } from '@material-ui/core';
import { QueryError } from 'src/web/components/UI/QueryError/QueryError';

const Arrivals = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(auth);
  const partner_id = +user['custom:partner_id'];
  const prm = useSelector(initialPrm);
  const currentPage = useSelector(page);
  const mdMatches = useMediaQuery('(max-width: 1020px)');
  const [arrivals, setArrivals] = useState<any[] | []>([]);
  const [open, setOpen] = useState<boolean>(false);

  const { error, loading, refetch } = useQuery(GET_PARTNER_ORDERS, {
    skip: !prm || currentPage !== 'arrivals',
    variables: {
      prm: normalizePrm(prm, partner_id),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (arrivalsData) => {
      if (prm?.offset) {
        setArrivals((prev: any) => {
          return [...prev, ...arrivalsData.v1_partners_orders.items];
        });
      } else {
        setArrivals(arrivalsData.v1_partners_orders.items);
      }
      dispatch(setLoading({ type: 'scroll', value: false }));
      dispatch(setLoading({ type: 'change', value: false }));
      dispatch(setOffset(arrivalsData.v1_partners_orders.offset));
    },
  });

  const adaptiveSortingIds = ['trip_name', 'customer', 'state_name'];
  const adaptiveSorting = sorting.filter((item) =>
    adaptiveSortingIds.includes(item.id)
  );

  useEffect(() => {
    dispatch(
      setInitialPrm({
        page: 'arrivals',
        filter: filters,
        detailedFilters: {
          ...detailedFilters,
          dateFrom: {
            field: 'date',
            operator: 'ge',
            value: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          },
          dateTo: {
            field: 'date',
            operator: 'le',
            value: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        sorting,
        search: {
          isActive: false,
          value: '',
          fields: ['search'],
        },
        calendar: null,
        prm: {
          limit: 15,
          where: [
            [
              {
                field: 'date',
                operator: 'ge',
                value: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
              },
              {
                field: 'date',
                operator: 'le',
                value: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
              },
            ],
          ],
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const currentSorting = mdMatches ? adaptiveSorting : sorting;
    dispatch(changeInitialSorting(currentSorting));

    if (arrivals.length) {
      dispatch(setLoading({ type: 'change', value: false }));
    }
  }, [mdMatches]);

  if (!arrivals.length || loading) {
    if (error) {
      return <QueryError refetch={() => refetch()} />;
    }
  }

  return (
    <>
      <DetailedFilters open={open} setOpen={setOpen} />
      <PageTable
        icon={<ArrivalsIcon />}
        title="Arrivals"
        content={<ArrivalsList arrivals={arrivals} />}
        error={error}
        headerWidth={mdMatches ? '8%' : '40%'}
        headerElement={<Header setOpen={setOpen} />}
      />
    </>
  );
};

export default Arrivals;
