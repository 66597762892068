import {
  IArrivalAdditionalOption,
  IArrivalDetail,
  IArrivalDetailUpdate,
  IArrivalTicket,
} from 'src/redux/Arrivals/interfaces';

type AdditionalOptions = { [id: string]: IArrivalAdditionalOption };
type Tickets = { [id: string]: IArrivalTicket };

type TripItem = {
  state: string;
  comment_partner: string;
  //resend_confirm: 0 | 1;
  //send_update_email: 0 | 1;
  //update_integrated?: 0 | 1;
  schedule_id: string;
  inventory_id?: string;
  arrival_date: string;
  //tickets: { [id: string]: IArrivalTicket };
  //additional_options: AdditionalOptions;
};

export const prepareUpdateOrder = (
  arrival: IArrivalDetail
): IArrivalDetailUpdate => {
  const preparedTickets = arrival.data.tickets.reduce<Tickets>((acc, curr) => {
    return {
      ...acc,
      [curr.id]: curr,
    };
  }, {});

  const preparedAdditionalOptions =
    arrival.data.additional_options.reduce<AdditionalOptions>((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr,
      };
    }, {});

  const arrivalDetailUpdate: { [id: string]: TripItem } = {
    [`${arrival.cart_item_id}`]: {
      state: arrival.state_name,
      comment_partner: arrival.data.comments.partner,
      //resend_confirm: 0,
      //send_update_email: 0,
      //update_integrated: 0,
      schedule_id: `${arrival.data.schedule.id}`,
      inventory_id: `${arrival.data.inventory.id}`,
      arrival_date: arrival.date,
      //tickets: preparedTickets,
      //additional_options: preparedAdditionalOptions,
    },
  };

  return {
    trip_items: JSON.stringify(arrivalDetailUpdate),
  };
};
