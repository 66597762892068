import React, { useState } from 'react';
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_REVIEW } from 'src/api/reviews';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import CloseIcon from 'src/assets/icons/light/close.svg';
import { useStyles } from '../styles';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';

interface Props {
  review?: IReview;
  setReview: React.Dispatch<React.SetStateAction<IReview | undefined>>;
  refetchList: () => void;
}

export const ResponseDialog = ({ review, refetchList, setReview }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //const [response, setResponse] = useState('');
  const { user } = useSelector(auth);

  const [updateReview] = useMutation(UPDATE_REVIEW, {
    onCompleted: () => {
      dispatch(
        setNotification({
          message: 'Reply was added',
          type: 'success',
        })
      );

      refetchList();
      setReview(undefined);
    },
    onError: (error) => {
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
    },
  });

  const handleClose = () => {
    setReview(undefined);
  };

  const handleSave = () => {
    if (!review) {
      return;
    }

    updateReview({
      variables: {
        prm: {
          review_id: review.id,
          reply_text: review.reply_text,
          user_id: +user['custom:partner_id'],
        },
      },
    });
  };

  if (!review) {
    return <></>;
  }

  return (
    <Dialog
      fullWidth={true}
      className={classes.dialog}
      open={!!review}
      onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>{review.title}</Box>
          <IconButton
            onClick={() => setReview(undefined)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogResponse}>{review.text}</Box>
        <div className={classes.textareaBox}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            value={review.reply_text || ''}
            onChange={(e) =>
              setReview((prev) => {
                if (!prev) return;
                return { ...prev, reply_text: e.target.value };
              })
            }
            className={classes.textarea}
            minRows={6}
            maxRows={8}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Box p={1} mr={1}>
          <Button
            onClick={handleClose}
            className={classes.cancelButton}
            variant="text">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={classes.saveButton}
            variant="contained">
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
