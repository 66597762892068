import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GET_PARTNER_ORDER } from 'src/api/partners';
import {
  setArrivalData,
  setReceivedData,
  setLoading,
} from 'src/redux/Arrivals/sliceArrival';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { ArrivalDetailsContent } from 'src/web/components/Arrivals/ArrivalDetails/ArrivalDetailsContent';
import { InnerPageHeader } from 'src/web/components/InnerPagesHeader';
import { Spiner } from 'src/web/components/Loaders';
import { setNotification } from 'src/web/features/notifications/sliceNotifications';
import { PageCardsLayout } from 'src/web/layouts/PageCardsLayout';

const ArrivalDetailedPage = () => {
  const history = useHistory();
  const { user } = useSelector(auth);
  const { id } = useParams<{ id: string }>();
  const partner_id = +user['custom:partner_id'];
  const dispatch = useDispatch();

  const [isSpiner, setIsSpiner] = useState<boolean>(false);

  const { loading } = useQuery(GET_PARTNER_ORDER, {
    variables: { prm: { partner_id: +partner_id, cart_item_id: +id } },
    onCompleted: (data) => {
      const arrivalData = { ...data.v1_partners_order };

      if (!arrivalData.id) {
        history.push('/404');
      }

      console.log('123 arrival data', arrivalData);
      arrivalData.date = arrivalData.date.split('T')[0];
      arrivalData.data = JSON.parse(arrivalData.data || '{}');
      arrivalData.data.tickets = Object.keys(
        arrivalData.data.tickets || {}
      ).map((id) => {
        const ticket = { ...arrivalData.data.tickets[id] };
        ticket.guests = Object.keys(ticket.guests || {}).map(
          (index) => ticket.guests[index]
        );

        return ticket;
      });

      arrivalData.data.additional_options = Object.keys(
        arrivalData.data.additional_options || {}
      )
        .filter((id) => id !== '1')
        .map((id) => arrivalData.data.additional_options[id]);

      dispatch(setArrivalData(arrivalData));
      dispatch(setReceivedData(arrivalData));
    },
    onError: (error) => {
      dispatch(
        setNotification({
          type: 'error',
          message: error.message,
          duaration: 5000,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [dispatch, loading]);

  return (
    <>
      {isSpiner && <Spiner fixed />}
      <PageCardsLayout
        header={
          <InnerPageHeader
            backLink={{
              path: '/arrivals',
              label: 'Arrivial Details',
            }}
          />
        }
        content={
          <>
            <ArrivalDetailsContent setIsSpiner={setIsSpiner} />
          </>
        }
      />
    </>
  );
};

export default ArrivalDetailedPage;
