import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';

import CloseIcon from 'src/assets/icons/light/close.svg';

import { useStyles } from './styles';
import { IPartner } from 'src/web/pages/Settings';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_PARTNER } from 'src/api/partners';
import { setNotification } from 'src/redux/Notifications/sliceNotifications';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  data: IPartner;
  setData: React.Dispatch<React.SetStateAction<IPartner | null>>;
};

export const EditNotificationsModal = ({
  open,
  setOpen,
  data,
  setData,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [updatePartner, { loading }] = useMutation(UPDATE_PARTNER, {
    onCompleted: () => {
      dispatch(
        setNotification({
          message: 'Info was updated successfully!',
          type: 'success',
        })
      );
      setOpen(false);
    },
    onError: (error) => {
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
    },
  });

  const onSubmitHandler = () => {
    updatePartner({
      variables: {
        prm: {
          user_id: data.id,
          id: data.id,
          data: {
            name: data.name,
            email: data.email,
            admin_only_email: data.admin_only_email,
          },
        },
      },
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      className={classes.editModalRoot}
      onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box className={classes.dialogHeader}>
          <Box className={classes.dialogHeaderTitle}>Edit</Box>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            className={classes.editInput}
            label="Main email"
            value={data.email}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setData((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  email: e.target.value,
                };
              })
            }
          />

          <TextField
            className={classes.editInput}
            label="Admin only email"
            value={data.admin_only_email}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              setData((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  admin_only_email: e.target.value,
                };
              })
            }
          />

          {/* <TextField
            className={classes.editInput}
            label="Accounting email"
            value="accounting@gmail.com"
            variant="outlined"
          />

          <TextField
            className={classes.editInput}
            label="Email for notifications"
            value="notifications@gmail.com"
            variant="outlined"
          /> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            onClick={() => setOpen(false)}
            className={classes.cancelButton}
            variant="text">
            Cancel
          </Button>
          <Button
            onClick={() => onSubmitHandler()}
            disabled={loading}
            className={classes.saveButton}
            variant="contained">
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
