import {
  Box,
  Typography,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import React from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  arrivalData,
  setArrivalData,
  setValue,
} from 'src/redux/Arrivals/sliceArrival';
import CloseIcon from 'src/assets/icons/light/close.svg';
import { useStyles } from '../styles';
import moment from 'moment';
import DateCalendar from 'src/assets/icons/DateCalendar.svg';
import { EDIT_ORDER } from 'src/api/orders';
import { useMutation } from '@apollo/client';
import { auth } from 'src/redux/Authentication/sliceAuthentication';
import { prepareUpdateOrder } from 'src/web/helpers/PrepareOrderData';
import { setNotification } from 'src/web/features/notifications/sliceNotifications';
import { IArrivalSchedule } from 'src/redux/Arrivals/interfaces';
import { dataParse } from 'src/helpers/ArrivalParse';
import { CustomSelect } from 'src/web/components/UI/CustomSelect';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  schedules: IArrivalSchedule[];
  setIsSpiner: React.Dispatch<React.SetStateAction<boolean>>;
}

const statusesArray = [
  { id: 'paid', value: 'paid', name: 'Paid' },
  { id: 'confirmed', value: 'confirmed', name: 'Confirmed' },
  { id: 'checked_in', value: 'checked_in', name: 'Checked In' },
  { id: 'unconfirmed', value: 'unconfirmed', name: 'Unconfirmed' },
  { id: 'no_show', value: 'no_show', name: 'No Show' },
];

export const EditModal = ({ open, setOpen, schedules, setIsSpiner }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSelector(auth);
  const { arrivalData: arrival, receivedData } = useSelector(arrivalData);

  const [upateOrder, { loading }] = useMutation(EDIT_ORDER, {
    onCompleted(data) {
      const currentData = JSON.parse(data.v1_orders_edit);
      setIsSpiner(false);
      if (!currentData.success) {
        return dispatch(
          setNotification({
            type: 'error',
            message: currentData.msg,
            duaration: 10000,
          })
        );
      }

      dispatch(
        setNotification({
          type: 'success',
          message: 'Data changed successfully!',
        })
      );
      const arrivalData = dataParse(
        JSON.parse(data.v1_orders_edit).data,
        arrival.id,
        arrival.cart_item_id
      );
      dispatch(setArrivalData(arrivalData));
    },
    onError(error) {
      dispatch(
        setNotification({
          type: 'error',
          message: error.message,
          duaration: 10000,
        })
      );
    },
  });

  const handleClose = () => {
    setOpen(false);
    dispatch(
      setValue({
        date: receivedData.date,
        state_name: receivedData.state_name,
        data: receivedData.data,
      })
    );
  };

  const onHandlerChange = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => {
    dispatch(setValue({ [key]: value }));
  };

  const newSchedules = schedules.map((item) => {
    return {
      ...item,
      name: moment.utc(item.time?.split('.')[0], 'HH:mm:ss').format('h:mma'),
    };
  });

  const onHandlerChangeSchedule = (value: string) => {
    const newItems = newSchedules.find(
      (item) => value === String(item.name)
    ) as IArrivalSchedule;

    dispatch(
      setValue({
        ...arrival,
        data: {
          ...arrival.data,
          schedule: {
            id: newItems.id,
            time: newItems?.name,
          },
        },
      })
    );
  };

  const onHandlerChangeComment = (value: string) => {
    dispatch(
      setValue({
        ...arrival,
        data: {
          ...arrival.data,
          comments: {
            partner: value,
          },
        },
      })
    );
  };

  const handleSave = () => {
    const preparedOrderData = prepareUpdateOrder(arrival);
    console.log('123 preparedOrderData', preparedOrderData);
    setIsSpiner(true);
    setOpen(false);
    upateOrder({
      variables: {
        prm: {
          cart_item_id: arrival.cart_item_id,
          id: arrival.id,
          user_id: +user['custom:partner_id'],
          data: preparedOrderData,
        },
      },
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(!open)}
        className={classes.dialog}>
        <DialogTitle>
          <Box>
            <Box
              display={'flex'}
              justifyContent="space-between"
              alignItems={'flex-start'}>
              <Typography className={classes.modalTitle}>Edit</Typography>
              <IconButton
                onClick={() => handleClose()}
                style={{
                  padding: '13px',
                  background: 'white',
                  boxShadow:
                    '0px 3.89312px 23.3587px rgba(189, 189, 189, 0.23)',
                }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider style={{ backgroundColor: '#FAFAFA' }} />
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: 'inherit' }}>
          <Box>
            <Box p={1} width={1} mt={1}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.datePickerWrapper}>
                <InputLabel className={classes.datePickerLabel} shrink>
                  Arrival date
                </InputLabel>
                <DatePicker
                  selected={
                    arrival.date
                      ? new Date(moment(arrival.date).toISOString())
                      : null
                  }
                  onChange={(date: any) => {
                    onHandlerChange({
                      key: 'date',
                      value: date
                        ? moment(moment(date).format('YYYY-MM-DD'))
                            .startOf('day')
                            .format('YYYY-MM-DD')
                        : null,
                    });
                  }}
                  dateFormat={'MMM dd, yyyy'}
                  disabledKeyboardNavigation
                  className={classes.datePicker}
                  customInput={
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DateCalendar />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
              </FormControl>
            </Box>
            <Box p={1} width={1} mt={1}>
              <CustomSelect
                label={'Schedule time'}
                list={newSchedules}
                placeholder="Choose time"
                onChange={(e: any) => {
                  onHandlerChangeSchedule(e.target.textContent);
                }}
                value={{
                  name:
                    newSchedules.find(
                      (item: any) =>
                        item.id.toString() === String(arrival.data.schedule.id)
                    )?.name || '',
                }}
                position="top"
              />
            </Box>
            <Box p={1} width={1} mt={1}>
              <CustomSelect
                label={'Status'}
                list={statusesArray}
                placeholder="Choose status"
                onChange={(e: any) => {
                  onHandlerChange({
                    key: 'state_name',
                    value:
                      statusesArray.find(
                        (item) => item.name === e.target.textContent
                      )?.value || '',
                  });
                }}
                value={statusesArray.find(
                  (item: any) => item.value === arrival.state_name
                )}
                position="top"
              />
            </Box>
            <Box p={1} width={1} mt={1}>
              <TextField
                className={classes.statusSelect}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  className: classes.selectorLabel,
                  shrink: true,
                }}
                value={arrival.data?.comments?.partner}
                label={'Partner Comment'}
                multiline
                minRows={3}
                onChange={(e) => onHandlerChangeComment(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={1} mr={1} marginBottom="30px" marginTop="3px">
            <Button
              onClick={() => handleClose()}
              className={classes.cancelButton}
              variant="text">
              Cancel
            </Button>
            <Button
              onClick={() => handleSave()}
              className={classes.saveButton}
              variant="contained"
              disabled={loading}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
