import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import {
  Popover,
  Box,
  Divider,
  Typography,
  IconButton,
} from '@material-ui/core';

import { getNotificationPath } from 'src/helpers/getNotificationsPathsMap';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';

import OptionsIcon from 'src/assets/icons/web/general/options.svg';

import NoNotificationsElement from './NoNotifications';
import NotificationActionsPopup from './NotificationActionsPopup';
import { useStyles } from './styles';
import { Spiner } from '../Loaders';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: any;
  setAnchorEl: React.Dispatch<any>;
};

const NotificationPopup = ({ open, setOpen, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeItemActions, setActiveItemActions] =
    useState<INotification | null>(null);
  const [anchorNotificationItem, setAnchorNotificationItem] =
    useState<HTMLButtonElement | null>(null);

  const {
    lastNotifications,
    lastNotificationsFilter,
    lastNotificationsLoading,
    readAllNotifications,
    readLastNotificationHandle,
    setLastNotificationsFilter,
  } = useNotificationsContext();

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onClickHandler = (notification: INotification) => {
    const path = getNotificationPath(notification);

    if (!notification.isReaded) {
      readLastNotificationHandle(notification.id);
    }

    if (path) {
      history.push(path);
      handleClose();
    }
  };

  const onClickActionsHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    item: INotification | null
  ) => {
    e?.stopPropagation();
    setActiveItemActions(item || null);
    setAnchorNotificationItem(e?.currentTarget || null);
  };

  const List = useMemo(() => {
    if (lastNotificationsLoading) {
      return (
        <Box className={classes.popupList} height="300px">
          <Spiner />
        </Box>
      );
    }

    if (!lastNotifications?.length) {
      return <NoNotificationsElement handleClose={handleClose} />;
    }

    return (
      <>
        <Box className={classes.popupList}>
          {lastNotifications.map((item, index: number) => (
            <React.Fragment key={item.id}>
              <Box
                className={classes.notificationItem}
                key={index}
                onClick={() => onClickHandler(item)}
                style={
                  item.isReaded
                    ? { background: 'white' }
                    : { background: '#F9FCFD' }
                }>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  style={{ marginLeft: '16px', marginRight: '32px' }}>
                  <Box
                    className={
                      !item.isReaded ? classes.newNotificationDot : undefined
                    }
                  />
                  <Box className={classes.popoverBox}>
                    <Box className={classes.popoverName}>{item?.title}</Box>
                    <Box className={classes.popoverId}># {item?.object_id}</Box>
                  </Box>

                  <Box className={classes.popoverInfo}>
                    <Box marginRight="15px">
                      {moment(+item.createdAt * 1000).format('MM/DD/YYYY')}
                    </Box>
                    <Box>
                      {moment(+item.createdAt * 1000).format('hh:mm:ss')}
                    </Box>
                  </Box>

                  <IconButton
                    className={classes.popupOptionsButton}
                    onClick={(e) => onClickActionsHandler(e, item)}>
                    <OptionsIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider
                className={classes.divider}
                style={
                  item.isReaded
                    ? { backgroundColor: '#0DD1C5' }
                    : { backgroundColor: 'white' }
                }
              />
            </React.Fragment>
          ))}
        </Box>
        <Box display="flex" justifyContent="center">
          <Link
            onClick={() => handleClose()}
            to={'/notifications'}
            className={classes.showMoreButton}>
            View all
          </Link>
        </Box>
      </>
    );
  }, [lastNotifications, lastNotificationsLoading]);

  return (
    <Popover
      id="notifications"
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={classes.popover}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <>
        <Box className={classes.popupDialog}>
          <Box className={classes.popupHeader}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingBottom="15px">
              <Typography className={classes.popupHeaderTitle}>
                Notifications
              </Typography>
              {/* <Typography
                className={classes.popupHeaderReadAll}
                onClick={() => {
                  readAllNotifications();
                  handleClose();
                }}>
                Mark all as readed
              </Typography> */}
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                className={clsx(classes.popupHeaderFilterButton, {
                  [classes.activePopupFilterButton]: !lastNotificationsFilter,
                })}
                onClick={() => setLastNotificationsFilter(undefined)}>
                <Typography>All</Typography>
              </Box>
              <Box
                className={clsx(classes.popupHeaderFilterButton, {
                  [classes.activePopupFilterButton]:
                    lastNotificationsFilter &&
                    !lastNotificationsFilter.showReaded,
                })}
                onClick={() =>
                  setLastNotificationsFilter({
                    showReaded: false,
                  })
                }>
                <Typography>New</Typography>
              </Box>
              <Box
                className={clsx(classes.popupHeaderFilterButton, {
                  [classes.activePopupFilterButton]:
                    lastNotificationsFilter &&
                    lastNotificationsFilter.showReaded,
                })}
                onClick={() =>
                  setLastNotificationsFilter({
                    showReaded: true,
                  })
                }>
                <Typography>Read</Typography>
              </Box>
            </Box>
          </Box>
          {List}
        </Box>
        <NotificationActionsPopup
          activeItem={activeItemActions}
          anchor={anchorNotificationItem}
          setActive={onClickActionsHandler}
        />
      </>
    </Popover>
  );
};

export default NotificationPopup;
